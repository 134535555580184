const siteMetadata = {
  appName: 'EntreCoach',
  appColor: '#fff',
  phoneNumber: false,
  siteUrl: '',
  siteLogo: '',
  siteLogoSquare: '',
  email: '',
  twitter: '',
  twitterHandle: '',
  facebook: '',
  instagram: '',
};

export default siteMetadata;
