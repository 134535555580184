import PropTypes from 'prop-types';
import React from 'react';
import dynamic from 'next/dynamic';
import HeadMeta from '../components/common/HeadMeta';
import {
  fetchHero, fetchPackage, fetchRecentPosts, fetchTestimonials,
} from '@/lib/apiCalls';

const Hero = dynamic(() => import('../components/sections/Hero'));
const AboutUs = dynamic(() => import('../components/sections/AboutUs'));
const Features = dynamic(() => import('../components/sections/Features'));
const Pricing = dynamic(() => import('../components/sections/Pricing'));
const Testimonials = dynamic(() => import('../components/sections/Testimonials'));
const Blog = dynamic(() => import('../components/sections/Blog'));
const Benefits = dynamic(() => import('../components/sections/Benefits'));

export default function Home({
  heroItems, packages, testimonials, posts,
}) {
  const metaProps = {
    title: 'Entre Coach - Desarrollo Personal y Profesional con Julie Ann De Armas',
    keywords:
      'Entre Coach, Julie Ann De Armas, Coaching Personal, Desarrollo Profesional, Servicios de Coaching',
    description:
      'Descubre en Entre Coach cómo Julie Ann De Armas puede guiarte en tu desarrollo personal y profesional con sesiones de coaching y psicología adaptadas a tus necesidades. Empieza tu transformación hoy.',
  };
  return (
    <>
      <h1 className="hidden">EntreCoach por Julie Ann De Armas</h1>
      <HeadMeta {...metaProps} />
      <Hero data={heroItems} />
      <AboutUs />
      <Features />
      <Benefits />
      <Pricing data={packages} />
      <Testimonials data={testimonials} />
      <Blog data={posts} />
    </>
  );
}

export async function getServerSideProps(context) {
  context.res.setHeader('Cache-Control', 'public, s-maxage=600, stale-while-revalidate=59');
  const [heroItems, packages, testimonials, posts] = await Promise.all([
    fetchHero(),
    fetchPackage(),
    fetchTestimonials(),
    fetchRecentPosts(),
  ]);

  return {
    props: {
      heroItems,
      packages,
      testimonials,
      posts,
    },
  };
}

Home.propTypes = {
  heroItems: PropTypes.array,
  packages: PropTypes.array,
  testimonials: PropTypes.array,
  posts: PropTypes.array,
};
