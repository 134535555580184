/* eslint-disable react/no-invalid-html-attribute */
/* eslint-disable react/require-default-props */
import React from 'react';
import Head from 'next/head';
import PropTypes from 'prop-types';
import siteMetadata from '../../data/siteMetadata';

export default function HeadMeta({
  title = 'EntreCoach',
  description = 'EntreCoach.',
  ogImage = null,
  keywords,
  publishedTime,
  schema,
}) {
  return (
    <Head>
      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />

      <title>{title}</title>
      {description && <meta name="description" content={description} />}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content={siteMetadata.twitterHandle} />
      <meta name="twitter:title" content={`${title} | ${siteMetadata.appName}`} />
      {description && <meta name="twitter:description" content={description} />}
      {keywords && <meta name="keywords" content={keywords} />}
      {ogImage && <meta name="twitter:image" content={ogImage} />}
      <meta property="og:locale" content="es_ES" />
      <meta property="og:site_name" content={siteMetadata.appName} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={`${title} | ${siteMetadata.appName}`} />
      {description && <meta property="og:description" content={description} />}
      {ogImage && <meta property="og:image" content={ogImage} />}
      {publishedTime && <meta property="article:published_time" content={publishedTime} />}
      {schema && <script type="application/ld+json">{JSON.stringify(schema)}</script>}

      <link rel="apple-touch-icon-precomposed" sizes="57x57" href="../../../favicon/apple-touch-icon-57x57.png" />
      <link rel="apple-touch-icon-precomposed" sizes="114x114" href="../../../favicon/apple-touch-icon-114x114.png" />
      <link rel="apple-touch-icon-precomposed" sizes="72x72" href="../../../favicon/apple-touch-icon-72x72.png" />
      <link rel="apple-touch-icon-precomposed" sizes="144x144" href="../../../favicon/apple-touch-icon-144x144.png" />
      <link rel="apple-touch-icon-precomposed" sizes="60x60" href="../../../favicon/apple-touch-icon-60x60.png" />
      <link rel="apple-touch-icon-precomposed" sizes="120x120" href="../../../favicon/apple-touch-icon-120x120.png" />
      <link rel="apple-touch-icon-precomposed" sizes="76x76" href="../../../favicon/apple-touch-icon-76x76.png" />
      <link rel="apple-touch-icon-precomposed" sizes="152x152" href="../../../favicon/apple-touch-icon-152x152.png" />
      <link rel="icon" type="image/png" href="../../../favicon/favicon-196x196.png" sizes="196x196" />
      <link rel="icon" type="image/png" href="../../../favicon/favicon-96x96.png" sizes="96x96" />
      <link rel="icon" type="image/png" href="../../../favicon/favicon-32x32.png" sizes="32x32" />
      <link rel="icon" type="image/png" href="../../../favicon/favicon-16x16.png" sizes="16x16" />
      <link rel="icon" type="image/png" href="../../../favicon/favicon-128.png" sizes="128x128" />
      <meta name="application-name" content="&nbsp;" />
      <meta name="msapplication-TileColor" content="#FFFFFF" />
      <meta name="msapplication-TileImage" content="mstile-144x144.png" />
      <meta name="msapplication-square70x70logo" content="mstile-70x70.png" />
      <meta name="msapplication-square150x150logo" content="mstile-150x150.png" />
      <meta name="msapplication-wide310x150logo" content="mstile-310x150.png" />
      <meta name="msapplication-square310x310logo" content="mstile-310x310.png" />

    </Head>
  );
}

HeadMeta.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string.isRequired,
  ogImage: PropTypes.string,
  keywords: PropTypes.string,
  publishedTime: PropTypes.string,
  schema: PropTypes.string,
};

HeadMeta.defaultProps = {
  title: 'EntreCoach',
  ogImage: '',
  keywords: 'EntreCoach',
};
